import type {
    GraphQLErrorLocation,
    GraphQLError,
    GraphQLResponse,
    GraphQLClientInterface,
} from '@bondvet/graphql-client/types';

export type {
    GraphQLErrorLocation,
    GraphQLError,
    GraphQLResponse,
    GraphQLClientInterface,
};

export interface MongoSyncedDocument {
    storedInMongoDb: boolean;
}

export interface Coordinates {
    latitude: number;
    longitude: number;
}

export type Projection<Key extends string | number | symbol = string> = Partial<
    Record<Key, 1 | '1' | -1 | '-1'>
>;

export interface OperationResult {
    success: boolean;
    error?: string | null;
}

export interface LastImportRun {
    timestamp: number;
    finished: boolean;
    offset: number | null;
    since: number | null;
}

export interface ImportMessagePayload<
    Payload extends Record<string, unknown> = Record<string, unknown>,
> {
    since?: number;
    offset: number;
    payload: Payload;
    finished?: boolean;
}

export interface ImportMessage<
    T extends string,
    Payload extends Record<string, unknown> = Record<string, unknown>,
> {
    type: T;
    payload: ImportMessagePayload<Payload>;
}

export const INTERNAL_ID_TYPE_NAME = 'InternalId';
export const EMAIL_TYPE_NAME = 'Email';
export const PHONE_NUMBER_TYPE_NAME = 'PhoneNumber';
export const INTL_PHONE_NUMBER_TYPE_NAME = 'IntlPhoneNumber';
export const PHONE_NUMBER_TYPE_NAME_OR_EMPTY = 'PhoneNumberOrEmpty';
export const INTL_PHONE_NUMBER_TYPE_NAME_OR_EMPTY = 'IntlPhoneNumberOrEmpty';

export const CoordinatesDefinition = `
type Coordinates {
  latitude: Float
  longitude: Float
}
`;

export interface ServiceAccountData {
    type: string;
    project_id: string;
    private_key_id: string;
    private_key: string;
    client_email: string;
    client_id: string;
    auth_uri: string;
    token_uri: string;
    auth_provider_x509_cert_url: string;
    client_x509_cert_url: string;
}

export const DAYS_OF_THE_WEEK = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
] as const;

export type DayOfTheWeek = (typeof DAYS_OF_THE_WEEK)[number];

export const DAYS_OF_THE_WEEK_FROM_MONDAY: DayOfTheWeek[] = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
];

export enum Services {
    automatedTests = 'automated-tests',
    background = 'background',
    database = 'database',
    firestore = 'firestore',
    functionsChecker = 'functions-checker',
    reverseProxy = 'reverse-proxy',
    storage = 'storage',
    telehealth = 'telehealth',
    tvScreensRaspberry = 'tv-screens-raspberry',
    clientApp = 'client-app',
    backend = 'backend',
    backoffice = 'backoffice',
    docs = 'docs',
    functions = 'functions',
    printRaspberry = 'print-raspberry',
    signing = 'signing',
    teamApp = 'team-app',
    tvScreens = 'tv-screens',
    vetspireExtension = 'vetspire-extension',
    website = 'website',
}

// Makes all fields allowed to be null | undefined
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]: T[SubKey] | null | undefined;
};

// Like Partial, but with certain required fields -> AtLeast<Something, 'thisIsRequired'>
export type AtLeast<T, K extends keyof T> = Partial<T> & Pick<T, K>;
