export enum Role {
    /** Team App roles */
    admin = 'admin',
    finance = 'finance',
    client = 'client',
    staff = 'staff',
    /** @deprecated */
    scheduling = 'scheduling',
    ukgScheduling = 'ukgScheduling',
    clinicData = 'clinicData',
    regionData = 'regionData',

    /** Vetspire / VS Extension roles */
    veterinarian = 'veterinarian',
    nurse = 'nurse',
    chatAdmin = 'chatAdmin',
    waitTimes = 'waitTimes',
    teamBonus = 'teamBonus',
    nurseScreen = 'nurseScreen',
    careCoordinator = 'careCoordinator',
    automatedTestRunner = 'automatedTestRunner',
    impersonateClients = 'impersonateClients',
    vetspireShiftAdmin = 'vetspireShiftAdmin',
    creditCards = 'creditCards',
    chargeNoShowFees = 'chargeNoShowFees',
    linkSurgeryReferrals = 'linkSurgeryReferrals',

    /** Shared / graphql roles */
    telehealth = 'telehealth',
    vetspire = 'vetspire',
    hqMural = 'hqMural',
    developer = 'developer',
    printer = 'printer',
    paymentEditor = 'paymentEditor',
    paymentAdmin = 'paymentAdmin',
    // used in backend => functions communication
    // via graphQL
    lead = 'lead',

    /**
     * @deprecated roles no longer in use
     */
    insights = 'insights',
    reporting = 'reporting',
    analytics = 'analytics',
    cmDashboard = 'cmDashboard',
    mdDashboard = 'mdDashboard',
    heatMapTab = 'heatMapTab',
    teamBonusTab = 'teamBonusTab',
}

export const ROLES = Object.freeze(Role);

export type Translations = Record<string, string>;

export type TranslationsModule = {
    en: Translations;
};

export default ROLES;

export function translationsLoader(): Promise<TranslationsModule> {
    return import('./i18n');
}
