import { ImportMessage } from '../common';
import type { VetspirePatientProtocol } from '../reminders';
import type { IdAndName, ObjectWithVetspireId, VetspireSex } from '../vetspire';
import type { PatientTag } from '../../generated/vetspire';

export interface AnimalVetspireData {
    id?: string | null;
}

/**
 * Sex
 * @enum
 */
export enum Sex {
    male = 'male',
    female = 'female',
    unknown = 'unknown',
}

export const SEX = Object.freeze(Sex);

export enum AgeUnit {
    weeks = 'weeks',
    months = 'months',
    years = 'years',
}

/**
 * collection: `animals` (Bond Vet part)
 *
 * Vetspire: https://developer.vetspire.com/object/Patient
 */
export interface Animal {
    /** alphanumeric id (e.g. "ppJ3FgGDPiBWWG4z5") */
    _id: string;
    userId: string;
    /** name */
    name?: string | null;
    /** species */
    species?: string | null;
    /** breed */
    breed?: string | null;
    /** color */
    color?: string | null;
    /** sex */
    sex?: Sex | null;
    /** neutered */
    neutered?: boolean | null;
    /** dateOfBirth */
    dateOfBirth?: Date | null;
    /** isEstimatedAge */
    isEstimatedAge?: boolean | null;
    /** microChip */
    microChip?: string | null;
    /** link data to Vetspire */
    _vetspire?: AnimalVetspireData | null;
    /** isDeceased */
    isDeceased: boolean;
    /** notes */
    notes?: string | null;
    // manual override from client
    noPastRecords?: boolean | null;
    // if pet was created using our adopter-form isAdopted
    // will be true and rescueOrganizationName will be set
    isAdopted?: boolean;
    rescueOrganizationName?: string;
}

export interface DocumentDownloadReason {
    createdAt: string;
    animalId: string;
    documentType: string;
    reason: string;
    sendAsEmail: boolean;
    recipientName?: string;
    recipientAddress?: string;
    userId: string;
}

export interface ImmunizationProduct extends ObjectWithVetspireId {
    alternativeName: string | null;
    name: string | null;
}

export interface VetspireImmunization extends ObjectWithVetspireId {
    administered: boolean | null;
    date?: string | null;
    dueDate: string | null;
    name: string;
    immunizationStatus: ImmunizationStatus | null;
    isRabies: boolean;
    isDiscontinued: boolean;
    insertedAt: string;
    manufacturer: string | null;
    rabiesTagNumber: string | null;
    site: string | null;
    updatedAt: string | null;
    type: ImmunizationType | null;
    route: RouteType | null;
    lotNumber: string | null;
    note: string | null;
    location: ImmunizationLocation | null;
    product: null | ImmunizationProduct;
    provider: IdAndName | null;
    patient: IdAndName;
    encounter: IdAndName | null;
    historical?: boolean | null;
}

export interface VetspirePatient<ClientType = { id: string }> {
    id: string;
    name: string | null;
    birthDate: string | null;
    neutered: boolean | null;
    sex: VetspireSex;
    species: string | null;
    microchip: string | null;
    breed: string | null;
    color: string | null;
    client: ClientType;
    insertedAt: string;
    isDeceased: boolean;
    isActive?: boolean;
    immunizations: Pick<VetspireImmunization, 'name' | 'dueDate'>[];
    protocols: readonly VetspirePatientProtocol[];
    alerts: readonly string[];
    tags?: readonly Pick<PatientTag, 'id'>[];
}

export interface VetspirePatientDocument {
    id: string;
    category: string;
    contentType: string;
    gmailAttachmentId: string | null;
    insertedAt: string;
    name: string;
    path: string;
    uploader: ObjectWithVetspireId;
}

/**
 * Bigquery dataset: `vetspire`
 * Bigquery table: `patientDocuments`
 *
 * Vetspire: https://developer.vetspire.com/object/PatientDocument
 */
export interface DbPatientDocument {
    /** numeric-as-string primary key in Vetspire (e.g. "1234") */
    id: string;

    /** Category */
    category: string;

    /** content type */
    contentType: string;

    /** gmail attachment ID */
    gmailAttachmentId: string | null;

    /** date & time, format YYYY-MM-DDTHH:mm:SS.FZ */
    insertedAt: string;

    /** name */
    name: string;

    /** path */
    path: string;

    /** ID of the provider who uploaded the document */
    uploaderId: string | null;

    /** numeric-as-string foreign key to {@link Animal}`_vetspire.id` */
    patientId: string;

    /** numeric-as-string foreign key to {@link User}`_vetspire.id` */
    clientId: string;

    /** used to clean up duplicates */
    importRun: number;
}

export const PUBSUB_TOPIC_IMPORT_PATIENT_DOCUMENTS = 'patientDocuments-import';

export const IMPORT_PATIENT_DOCUMENTS = 'importPatientDocuments';

export type ImportPatientDocumentsMessage = ImportMessage<
    typeof IMPORT_PATIENT_DOCUMENTS
>;

export enum ImmunizationStatus {
    active = 'ACTIVE',
    completed = 'COMPLETED',
    declined = 'DECLINED',
    enteredInError = 'ENTERED_IN_ERROR',
    pending = 'PENDING',
}

export enum ImmunizationType {
    booster = 'BOOSTER',
    initial = 'INITIAL',
}

export enum RouteType {
    'drops' = 'DROPS',
    'intramuscular' = 'INTRAMUSCULAR',
    'intranasal' = 'INTRANASAL',
    'intravenously' = 'INTRAVENOUSLY',
    'ointment' = 'OINTMENT',
    'oral' = 'ORAL',
    'subcutaneous' = 'SUBCUTANEOUS',
    'topical' = 'TOPICAL',
}

export interface ImmunizationLocation {
    id: string;
    name: string;
    timezone: string | null;
}

export interface Immunization
    extends Omit<
        VetspireImmunization,
        'id' | 'insertedAt' | 'updatedAt' | 'location' | 'product'
    > {
    _id: string;
    userId: string;
    animalId: string;
    insertedAt: Date;
    updatedAt: Date | null;
    locationId: string | null;
    _vetspire: { id: string };
    productId: null | string;
    alternativeName: null | string;
}

export const ANIMALS_COLLECTION_NAME = 'animals';

export const DOCUMENT_DOWNLOAD_REASON_COLLECTION_NAME =
    'documentDownload.reasons';
export const ALLERGIES_COLLECTION_NAME = 'animals.allergies';
export const IMMUNIZATIONS_COLLECTION_NAME = 'animals.immunizations';
export const MEDICATIONS_COLLECTION_NAME = 'animals.medications';
export const PATIENT_PROTOCOLS_COLLECTION_NAME = 'animals.patientProtocols';

export interface VetspireAllergy extends ObjectWithVetspireId {
    insertedAt: string;
    name: string | null;
}

export interface Allergy extends Omit<VetspireAllergy, 'id' | 'insertedAt'> {
    _id: string;
    insertedAt: Date;
    _vetspire: { id: string };
}

export enum MedicationStatus {
    active = 'ACTIVE',
    any = 'ANY',
    completed = 'COMPLETED',
    declined = 'DECLINED',
    deleted = 'DELETED',
    pending = 'PENDING',
    refilled = 'REFILLED',
    stopped = 'STOPPED',
}

export interface VetspireMedication extends ObjectWithVetspireId {
    declined: boolean | null;
    dispensed: boolean | null;
    name: string | null;
    note: string | null;
    prescriber: null | {
        id: string;
        givenName: string | null;
        familyName: string | null;
    };
    productId: string | null;
    quantity: string | null;
    quantityUnit: string | null;
    refills: number | null;
    refillsUsed: number | null;
    sig: string | null;
    start: string | null;
    insertedAt?: string | null;
    status: MedicationStatus | null;
    stop: string | null;
}

export interface Medication
    extends Omit<VetspireMedication, 'id' | 'start' | 'stop'> {
    _id: string;
    start: Date | null;
    stop: Date | null;
}

// NOT including _vetspire.id here, as patientProtocols in VS appear to just be the related protocol ID, thus are not unique
export interface PatientProtocol extends Omit<VetspirePatientProtocol, 'id'> {
    _id: string;
    animalId?: string;
    userId?: string;
}

export const SexDefinition = `
enum Sex {
    female
    male
    unknown
}
`;

export const AgeUnitDefinition = `
enum AgeUnit {
    weeks
    months
    years
}
`;

export const ANIMAL_CAMPAIGN_LOGS_COLLECTION_NAME = 'animals.campaignLogs';

export interface AnimalCampaignLog {
    _id: string;
    createdAt: Date;
    campaign: 'birthday' | 'dental' | 'drip';
    job: 'schedule' | 'send';
    month: number;
    action: string;
    data?: Record<string, unknown>;
}
